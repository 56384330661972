@font-face {
  font-family: "JetBrainsMono";
  src: url("https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-Regular.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Regular.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/ttf/JetBrainsMono-Regular.ttf")
      format("truetype");
  font-weight: 400;
  font-style: normal;
}

.mt-10 {
  margin-top: 33%;
}

.btn-circle {
  font-size: 2rem;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
}

.react-router-appear {
  opacity: 0;
  transition: opacity ease-in-out 300ms;
}

.react-router-appear-done {
  opacity: 1;
  transition: opacity ease-in-out 300ms;
}

.custom.custom-file *,
input.custom-file-input {
  cursor: pointer;
}

.paste-edit {
  width: 100%;
  max-width: 1024px;
}

.paste-view {
  width: 100%;
  max-width: 1024px;
}

@media only screen and (max-width: 992px) {
  .mt-10 {
    margin-top: 10%;
  }

  .mb-osm-3 {
    margin-bottom: 1rem !important;
  }

  .center-osm {
    margin: auto;
  }
}

@media only screen and (max-width: 992px) {
  .paste-edit {
    height: 100vh;
  }

  .paste-view {
    height: 100vh;
  }
}

@media only screen and (min-width: 992px) {
  .paste-edit {
    aspect-ratio: 22/9;
  }

  .paste-view {
    aspect-ratio: 16/9;
  }

  body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-image: url("/tile.png");
    background-repeat: repeat;
  }

  .rootbox-flex {
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .rootbox {
    z-index: 99999;
    border: 8px solid var(--white);
    border-radius: 8px;
    width: 896px;
    aspect-ratio: 16/9;
    background-color: var(--white);
  }
}
